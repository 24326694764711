import React, { useState } from 'react'
import { useSelector } from 'react-redux';

import moment from 'moment-timezone'

import { Img } from 'react-image';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl'

import { TravelClass, messages } from 'components/pages/Rewards/constants';
import { FlightSegment, Itinerary } from 'components/pages/Rewards/Flights/types';
import { isAmenityPopulated } from 'components/pages/Rewards/Flights/utils';

import { IETCodes } from 'utils/constants';

import styles from 'styles/styles';
import { getDurationAsString } from 'utils/utils';

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as CaretUp } from "assets/svg/CaretUp.svg";

type ItineraryReviewProps = {
    option: Itinerary,
    isOutbound: boolean,
}

const ItineraryReview = (props: ItineraryReviewProps) => {
    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)

    // Component state
    const [isOpen, setIsOpen] = useState(true)

    const toggleIsOpen = () => { 
        setIsOpen(!isOpen)
    }

    // Right now the itinerary progress bar only shows the ratio of flight times, and ignores connections
    const getSegmentFlexRatio = (segment: FlightSegment) => {
        const segmentLength = Number(segment.duration)
        const totalSumOfSegmentLengths = option.slices[0].segments.map((segment: FlightSegment) => Number(segment.duration)).reduce((counter: number, duration: number) => counter + duration, 0)

        return segmentLength / totalSumOfSegmentLengths
    }

    const marketingVsOperatingCarrierMismatch = (option: any, segment: any): boolean => {
        return (segment.carrierCode !== segment.operatingCarrierCode || segment.operatingCarrierCode !== option.validatingCarrier)
    }

    const { option, isOutbound } = props;

    const carrierCode = IETCodes.includes(option.carrierCode) ? option.slices[0].segments[0].operatingCarrierCode.toUpperCase() : option.carrierCode.toUpperCase();
    const arrivalDateDiffInDays = Number(moment(option.slices.at(-1)?.segments.at(-1)?.localArrivalTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))

    return <Container>
         <Header onClick={toggleIsOpen}>
            {isMobile ? <div className='d-flex w-100 justify-content-between align-items-center'>
                <div>
                    <DirectionalLabel>{isOutbound ? 'Departure' : 'Return'}</DirectionalLabel>
                    <DateLabel>{moment(option.slices[0].segments[0].localDepartureTimeDate).format('ddd, MMM D')}</DateLabel>
                </div>
                {isOpen ? <StyledCaretUp/> : <StyledCaretDown/>}
            </div> : <>
                <div style={{width: '25%', height: '100%', display: 'flex', justifyContent: 'space-between', padding: '0 2px'}}>
                    <div>
                        <DirectionalLabel>{isOutbound ? 'Departure' : 'Return'}</DirectionalLabel>
                        <DateLabel>{moment(option.slices[0].segments[0].localDepartureTimeDate).format('ddd, MMM D')}</DateLabel>
                    </div>
                    <div className='h-100 d-flex align-items-center'>
                        <Logo isOpen={isOpen} src={[
                            `/static/img/airlineLogos/mini/${carrierCode.toUpperCase()}.png`,
                            '/static/img/airlineLogos/mini/default.png'
                        ]} />
                    </div>
                </div>
                <div style={{width: '50%', padding: '0 20px'}}>
                    <div className='w-100 d-flex justify-content-between' style={{fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '10px'}}>
                        <div>{option.slices[0].segments[0].departurePointName}</div>
                        <div>{option.slices.at(-1)?.segments.at(-1)?.arrivalPointName}</div>
                    </div>
                    <div className='w-100 d-flex justify-content-between align-items-center' style={{fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '16px'}}>
                        <div>{option.slices[0].segments[0].departurePoint}</div>
                        <div style={{ display: 'flex', alignItems: 'center', flex: 1, margin: '6px'}}>
                            {option.slices[0].segments.map((segment: FlightSegment, index: number) => <>
                                {index !== 0 && <ItineraryPoint/>}
                                <ItineraryLine segmentRatio={getSegmentFlexRatio(segment)}/>
                            </>)}
                        </div>
                        <div>{option.slices[0].segments.at(-1)?.arrivalPoint}</div>
                    </div>
                    <div className='w-100 d-flex justify-content-between' style={{fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '16px'}}>
                        <div>{moment(option.slices[0].segments[0].localDepartureTimeDate).format('h:mm A')}</div>
                        <div>{moment(option.slices.at(-1)?.segments.at(-1)?.localArrivalTimeDate).format('h:mm A')}{arrivalDateDiffInDays > 0 && <Superscript>+{arrivalDateDiffInDays}</Superscript>}</div>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center' style={{width: '25%', padding: '0 16px'}}>
                    <div>
                        <div style={{fontSize: '10px'}}>Total Travel Time</div>
                        <div style={{fontSize: '16px'}}>{`${Math.floor(option.slices[0].duration / 60)} hr ${!!(option.slices[0].duration % 60) ? `${(option.slices[0].duration % 60)} min` : ''}`}</div>
                    </div>
                    {isOpen ? <StyledCaretUp/> : <StyledCaretDown/>}
                </div>
            </>}
        </Header>
        <AnimatePresence>
            {isOpen && <Expandable isOpen={isOpen} initial={{opacity: 0, height: 0}} exit={{opacity: 0, height: 0}} animate={{opacity: 1, height: 'auto'}}>
                {isMobile ? <div style={{padding: '15px'}}>
                    <div className='w-100 d-flex justify-content-between' style={{fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '10px'}}>
                        <div>{option.slices[0].segments[0].departurePointName}</div>
                        <div>{option.slices[0].segments.at(-1)?.arrivalPointName}</div>
                    </div>
                    <div className='w-100 d-flex justify-content-between align-items-center' style={{fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '16px'}}>
                        <div>{option.slices[0].segments[0].departurePoint}</div>
                        <div style={{ display: 'flex', alignItems: 'center', flex: 1, margin: '6px'}}>
                            {option.slices[0].segments.map((segment: FlightSegment, index: number) => <>
                                {index !== 0 && <ItineraryPoint/>}
                                <ItineraryLine segmentRatio={getSegmentFlexRatio(segment)}/>
                            </>)}
                        </div>
                        <div>{option.slices[0].segments.at(-1)?.arrivalPoint}</div>
                    </div>
                    <div className='w-100 d-flex justify-content-between' style={{fontFamily: styles.Font.Family.MonumentGrotesk, fontSize: '16px'}}>
                        <div>{moment(option.slices[0].segments[0].localDepartureTimeDate).format('h:mm A')}</div>
                        <div>{moment(option.slices[0].segments.at(-1)?.localArrivalTimeDate).format('h:mm A')}{arrivalDateDiffInDays > 0 && <Superscript>+{arrivalDateDiffInDays}</Superscript>}</div>
                    </div>
                    {option.slices[0].segments.map((segment: FlightSegment, index: number) => {
                        const departureDayDiff =  Number(moment(segment.localDepartureTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))
                        const arrivalDayDiff =  Number(moment(segment.localArrivalTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))
                        
                        return <VerticalLegContainer>
                            <div className='d-flex'>
                                <VerticalLegBar>
                                    <LargeDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <LargeDot/>
                                </VerticalLegBar>
                                <LegDetailsContainer>
                                    <LegAirportLabel>{moment(segment.localDepartureTimeDate).format('h:mm a')}{departureDayDiff > 0 && <Superscript>+{departureDayDiff}</Superscript>} &bull; <ErrorText error={index > 0 && option.slices[0].segments[index - 1]?.arrivalPoint !== segment.departurePoint}>{`${segment.departurePointName} (${segment.departurePoint})`}</ErrorText></LegAirportLabel>
                                    <LegTravelTime>Travel Time: {getDurationAsString(segment.duration)}</LegTravelTime>
                                    <LegAirportLabel>{moment(segment.localArrivalTimeDate).format('h:mm a')}{arrivalDayDiff > 0 && <Superscript>+{arrivalDayDiff}</Superscript>} &bull; <span>{`${segment.arrivalPointName} (${segment.arrivalPoint})`}</span></LegAirportLabel>
                                </LegDetailsContainer>
                            </div>
                            <AirplaneDetails>
                                <LogoContainer>
                                    <Logo src={[
                                        `/static/img/airlineLogos/mini/${(segment.operatingCarrierCode || carrierCode).toUpperCase()}.png`,
                                        '/static/img/airlineLogos/mini/default.png'
                                    ]} />
                                </LogoContainer>
                                <div>
                                    {segment.operatingCarrierName} &bull; {messages.SearchOptions.TravelClass[segment.cabin as TravelClass]} &bull; {segment.aircraft || <span style={{ opacity: 0.5 }}>Aircraft model not available</span>} &bull; {segment.carrierCode} {segment.fltNumber}
                                    {marketingVsOperatingCarrierMismatch(option, segment) && <OperatedBy>
                                        <CarrierDetails>
                                            Ticket sold by {option.airlineName} &bull; Flight operated by {segment.operatingCarrierName}
                                        </CarrierDetails>
                                    </OperatedBy>}
                                </div>
                            </AirplaneDetails>
                            <Amenities>
                                {isAmenityPopulated(segment.legs[0].amenities.seatType) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/${segment.legs[0].amenities.seatType.split('_')[0]}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.${segment.legs[0].amenities.seatType.split('_')[0]}`} values={{ legroom: segment.legs[0].amenities.seatType.split('_')[1] || null }} />
                                </div>}
                                {isAmenityPopulated(segment.legs[0].amenities.wifi) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/wifi-${segment.legs[0].amenities.wifi.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.wifi-${segment.legs[0].amenities.wifi.toLowerCase()}`} />
                                </div>}
                                {isAmenityPopulated(segment.legs[0].amenities.power) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/power-${segment.legs[0].amenities.power.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.power-${segment.legs[0].amenities.power}`} />
                                </div>}
                                {isAmenityPopulated(segment.legs[0].amenities.entertainment) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/entertainment-${segment.legs[0].amenities.entertainment.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.entertainment-${segment.legs[0].amenities.entertainment}`} />
                                </div>}
                                {segment.legs[0].amenities.skypub && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/skypub.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.skypub`} />
                                </div>}
                            </Amenities>
                            {segment.connection && <ConnectionDetails>
                                <ErrorText error={segment.connection > 240}>{getDurationAsString(segment.connection)}</ErrorText> &bull; {`${segment.arrivalPointName} (${segment.arrivalPoint})`}
                            </ConnectionDetails>}
                        </VerticalLegContainer>
                    })}
                </div> : option.slices[0].segments.map((segment: FlightSegment, index: number) => {
                    const departureDayDiff =  Number(moment(segment.localDepartureTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))
                    const arrivalDayDiff =  Number(moment(segment.localArrivalTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))
                    
                    return <div key={index}>
                        <div className='d-flex'>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'end', paddingTop: '20px'}}>
                                <Logo src={[
                                    `/static/img/airlineLogos/mini/${(segment.operatingCarrierCode || carrierCode).toUpperCase()}.png`,
                                    '/static/img/airlineLogos/mini/default.png'
                                ]} />
                            </div>
                            <div style={{width: '50%'}}>
                                <div className='d-flex'>
                                    <VerticalLegBar>
                                        <LargeDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <SmallDot/>
                                        <LargeDot/>
                                    </VerticalLegBar>
                                    <LegDetailsContainer>
                                        <LegAirportLabel>{moment(segment.localDepartureTimeDate).format('h:mm a')}{departureDayDiff > 0 && <Superscript>+{departureDayDiff}</Superscript>} &bull; <ErrorText error={index > 0 && option.slices[0].segments[index - 1]?.arrivalPoint !== segment.departurePoint}>{`${segment.departurePointName} (${segment.departurePoint})`}</ErrorText></LegAirportLabel>
                                        <LegTravelTime>Travel Time: {getDurationAsString(segment.duration)}</LegTravelTime>
                                        <LegAirportLabel>{moment(segment.localArrivalTimeDate).format('h:mm a')}{arrivalDayDiff > 0 && <Superscript>+{arrivalDayDiff}</Superscript>} &bull; <span>{`${segment.arrivalPointName} (${segment.arrivalPoint})`}</span></LegAirportLabel>
                                    </LegDetailsContainer>
                                </div>
                            </div>
                            <Amenities style={{width: '25%'}}>
                                {isAmenityPopulated(segment.legs[0].amenities.seatType) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/${segment.legs[0].amenities.seatType.split('_')[0]}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.${segment.legs[0].amenities.seatType.split('_')[0]}`} values={{ legroom: segment.legs[0].amenities.seatType.split('_')[1] || null }} />
                                </div>}
                                {isAmenityPopulated(segment.legs[0].amenities.wifi) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/wifi-${segment.legs[0].amenities.wifi.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.wifi-${segment.legs[0].amenities.wifi.toLowerCase()}`} />
                                </div>}
                                {isAmenityPopulated(segment.legs[0].amenities.power) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/power-${segment.legs[0].amenities.power.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.power-${segment.legs[0].amenities.power}`} />
                                </div>}
                                {isAmenityPopulated(segment.legs[0].amenities.entertainment) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/entertainment-${segment.legs[0].amenities.entertainment.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.entertainment-${segment.legs[0].amenities.entertainment}`} />
                                </div>}
                                {segment.legs[0].amenities.skypub && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/skypub.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.skypub`} />
                                </div>}
                            </Amenities>
                        </div>
                        <AirplaneDetails>
                            {segment.operatingCarrierName} &bull; {messages.SearchOptions.TravelClass[segment.cabin as TravelClass]} &bull; {segment.aircraft || <span style={{ opacity: 0.5 }}>Aircraft model not available</span>} &bull; {segment.carrierCode} {segment.fltNumber}
                            {marketingVsOperatingCarrierMismatch(option, segment) && <div className='d-flex align-items-center'>
                                <CarrierDetails>
                                    Ticket sold by {option.airlineName} &bull; Flight operated by {segment.operatingCarrierName}
                                </CarrierDetails>
                            </div>}
                        </AirplaneDetails>
                        {segment.connection > 0 && <ConnectionDetails>
                            <ErrorText error={segment.connection > 240}>{getDurationAsString(segment.connection)}</ErrorText> &bull; {`${segment.arrivalPointName} (${segment.arrivalPoint})`}
                        </ConnectionDetails>}
                    </div>
                })}
            </Expandable>}
        </AnimatePresence>
    </Container>
}

const Superscript = styled.span`
    vertical-align: super;
    font-size: 10px;
`

type ItineraryLineProps = {
    segmentRatio: number,
}

const ItineraryLine = styled.div<ItineraryLineProps>`
    flex: ${props => props.segmentRatio};
    height: 2px;
    background-color: ${styles.Color.TaekusBlue};
    margin: 6px 0;
`

const ItineraryPoint = styled.div`
    border-radius: 50%;
    background-color: ${styles.Color.TaekusBlue};
    width: 4px;
    height: 4px;
    margin: 3px;
`

const StyledCaretUp = styled(CaretUp)`
    height: 5px;
    width: auto;
`

const StyledCaretDown = styled(CaretDown)`
    height: 5px;
    width: auto;
`

const OperatedBy = styled.div`
    display: flex;
    align-items: center;
    ${styles.MediaQueries.Desktop} {
        margin-left: 22px;
        margin-top: 20px;
    }
    ${styles.MediaQueries.Mobile} {
        // margin-top: 20px;
    }
`

const DirectionalLabel = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 14.88px */
    letter-spacing: 0.12px;
    ${styles.MediaQueries.Desktop} {
        font-size: 14px;
        opacity: 0.6;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 12px;
        opacity: 0.4;
    }
`

const DateLabel = styled.div`
    color: #0E0E0E;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 19.84px */
    letter-spacing: 0.16px;
    ${styles.MediaQueries.Desktop} {
        font-size: 24px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 16px;
    }
`

type ErrorTextProps = {
    error: boolean,
}

const ErrorText = styled.span<ErrorTextProps>`
    color: ${props => props.error ? 'red' : 'black'};
`

type ExpandableProps = {
    isOpen: boolean
}

const Expandable = styled(motion.div)<ExpandableProps>``

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`

const Amenities = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: 12px;
    opacity: 0.5;
`

const AmenityIcon = styled.img`
    margin: 4px 8px 4px 0;
    width: 12px;
    height: auto;
`

const VerticalLegContainer = styled.div`
    ${styles.MediaQueries.Mobile} {
        margin-top: 10px;
    }
`

const VerticalLegBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
    ${styles.MediaQueries.Desktop} {
        margin-left: 30px;
    }
    ${styles.MediaQueries.Mobile} {
        
    }
`

const AirplaneDetails = styled.div`
    ${styles.MediaQueries.Desktop} {
        margin-left: calc(25% + 52px);
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: ${styles.Font.Size.Small};
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 12px;
        padding-top: 20px;
        display: flex;
        align-items: center;
    }
`

const CarrierDetails = styled.div`
    font-size: 10px;
`

const ConnectionDetails = styled.div`    
    border-top: 1px solid ${styles.Color.Grey};
    border-bottom: 1px solid ${styles.Color.Grey};
    flex: 1;
    padding: ${styles.Spacing.XS} 0;
    font-size: ${styles.Font.Size.Small};
    ${styles.MediaQueries.Desktop} {
        width: calc(50% - 52px);
        margin: 0 46px 30px 46px;
        margin-left: calc(25% + 52px);
    }
    ${styles.MediaQueries.Mobile} {
        margin: 20px 0;
    }
`

const LegAirportLabel = styled.div`
    font-size: ${styles.Font.Size.Small};
    font-weight: bold;
`

const LegTravelTime = styled.div`
    font-size: ${styles.Font.Size.Small};
    flex: 1;
    display: flex;
    align-items: center;
    opacity: 0.5;
`

const LegDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`

type LogoProps = {
    isOpen?: boolean,
}

const Logo = styled(Img)<LogoProps>`
    ${styles.MediaQueries.Desktop} {
        width: auto;
        height: 50px;
        opacity: ${props => !props.isOpen === false ? 0 : 1};
        ${styles.Animation.transitionStyles}
    }
    ${styles.MediaQueries.Mobile} {
        width: 12px;
        height: auto;
        margin: 4px 8px 4px 0;
    }
`

const Header = styled.div`
    display: flex;
    white-space: nowrap;
    cursor: pointer;
    font-size: ${styles.Font.Size.Small};
    ${styles.MediaQueries.Mobile} {
        height: 62px;
        padding: 15px;
        padding-right: 25px;
    }
    ${styles.MediaQueries.Desktop} {
        height: 100px;
        align-items: center;
        padding: 20px 0;
    }
    ${styles.Animation.transitionStyles}
    &:hover {
		background-color: rgba(139, 139, 139, 0.06);
	}
`

const LargeDot = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid ${styles.Color.Grey};
    margin: 3.5px 0;
`

const SmallDot = styled.div`
    background-color: ${styles.Color.Grey};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 3px 0;
`

const Container = styled.div`
    ${styles.Animation.transitionStyles}
    height: min-content;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-family: ${styles.Font.Family.MonumentGrotesk};
    overflow: hidden;
    ${styles.MediaQueries.Desktop} {
        margin-bottom: 50px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-bottom: 20px;
    }
`


export default ItineraryReview