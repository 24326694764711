import { TravelerDetails, TravelerDetailsErrors, TravelerInfo, TravelerInfoErrors } from "components/pages/Rewards/Flights/types"

export const defaultTravelerInfo: TravelerInfo = {
    email: undefined,
    phone: '+1',
    selectedTravelerUuids: [],
}

export const defaultTravelerInfoErrors: TravelerInfoErrors = {
    email: undefined,
    phone: undefined,
    passengers: undefined,
}

export const defaultTravelerDetails: TravelerDetails = {
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    dateOfBirth: '',
    gender: undefined,
    knownTravelerNumber: undefined,
    redressNumber: undefined,
    VNAFrequentFlyerNumber: undefined,
    passportNumber: undefined,
    passportCountry: undefined,
    passportExpDate: undefined,
    passportImage: undefined,
    citizenshipCountry: undefined,
    residenceCountry: undefined
}

export const defaultTravelerDetailsErrors: TravelerDetailsErrors = {
    firstName: undefined,
    lastName: undefined,
    dateOfBirth: undefined,
    gender: undefined,
    knownTravelerNumber: undefined,
    redressNumber: undefined,
    VNAFrequentFlyerNumber: undefined,
    passportNumber: undefined,
    passportCountry: undefined,
    passportExpDate: undefined,
    passportImage: undefined,
    citizenshipCountry: undefined,
    residenceCountry: undefined
}

export const PassengerValidationErrorLabels = {
    FirstName: {
        MissingValue: 'First name is a required field.'
    },
    LastName: {
        MissingValue: 'Last name is a required field.'
    },
    Passengers: {
        TooFewPassengers: "You have selected an insufficient number of passengers.",
        NoAdult: 'None of the selected travelers are over 18. You must add an adult to book this flight.',
        Incomplete: 'One or more of the selected passengers need additional details.',
    },
    DateOfBirth: {
        MissingValue: 'Date of birth is a required field.',
        Exceeds110: 'Primary passenger must be under 110 years old.',
        Under18: 'Primary passenger must be at least 18 years old.',
        FutureDate: "Date of birth cannot be after today's date.",
    },
    Email: {
        Invalid: 'Please enter a valid email address.'
    },
    Phone: {
        Invalid: 'Please enter a valid phone number.'
    },
    Gender: {
        MissingValue: 'Please select a gender.'
    },
    Passport: {
        Country: {
            MissingValue: 'Passport country is a required field.'
        },
        Number: {
            MissingValue: 'Passport number is required for international flights.',
            TooLong: "Passport number can not be longer than 9 characters.",
        },
        ExpDate: {
            MissingValue: 'Passport expiration date is required for international flights.',
            Expired: "Passport expiration date cannot be before today's date.",
            TooFarAway: "Please enter a valid passport expiration date."
        },
        Image: {
            Missing: 'Passport image is required.',
        },
    },
    ResidenceCountry: {
        MissingValue: 'Residence country is a required field.'
    },
    CitizenshipCountry: {
        MissingValue: 'Citizenship country is a required field.'
    },
    PassportImage: {
        MissingValue: 'Please submit a valid passport image.',
        ExceedsSize: 'Image must be less than 5MB.',
        InvalidType: 'Image format must be one of JPG, PNG.',
    },
    KnownTravelerNumber: {
        CharacterLength: 'Known Traveler Number must be 9 or 10 digits.',
    }
}

export const ValidationRequirements = {
    PassportImage: [
        "Supported formats: HEIC, JPG, PNG.",
        "Maximum file size: 5 MB"
    ]
}

export const VALID_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/heic"]

export const StepUpText = {
    header: "Traveler information: Our airline partner, Vietnam Airlines, requires all passengers to upload a government-issued ID in order to book partner tickets with them over any platform. We'll pass these IDs along to them to ensure that your ticket is booked smoothly. By uploading this document, you agree to Taekus sharing your information to Vietnam Airlines for the purpose of verifying your identity."
}
