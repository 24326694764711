import moment from 'moment'
import API from 'services/api'

const featureName = 'FlightSearch'

const ACTION_UPDATE_FLIGHT_SEARCH_VALUE = `${featureName}/UPDATE_VALUE`
const ACTION_UPDATE_FLIGHT_SEARCH_PARAMS = `${featureName}/UPDATE_SEARCH_PARAMS`
const ACTION_CLEAR_ITINERARY = `${featureName}/CLEAR_ITINERARY`
const ACTION_CLEAR_ITINERARY_OPTIONS = `${featureName}/CLEAR_ITINERARY_OPTIONS`

const ACTION_ITINERARY_SEARCH_START = `${featureName}/ITINERARY_SEARCH_START`
const ACTION_ITINERARY_SEARCH_CANCEL = `${featureName}/ITINERARY_SEARCH_CANCEL`
const ACTION_ITINERARY_SEARCH_COMPLETE = `${featureName}/ITINERARY_SEARCH_COMPLETE`
const ACTION_ITINERARY_SEARCH_ERROR = `${featureName}/ITINERARY_SEARCH_ERROR`

const ACTION_AWARD_SEARCH_START = `${featureName}/AWARD_SEARCH_START`
const ACTION_AWARD_SEARCH_CANCEL = `${featureName}/AWARD_SEARCH_CANCEL`
const ACTION_AWARD_SEARCH_COMPLETE = `${featureName}/AWARD_SEARCH_COMPLETE`
const ACTION_AWARD_SEARCH_ERROR = `${featureName}/AWARD_SEARCH_ERROR`

const ACTION_SUPP_AWARD_SEARCH_START = `${featureName}/SUPP_AWARD_SEARCH_START`
const ACTION_SUPP_AWARD_SEARCH_CANCEL = `${featureName}/SUPP_AWARD_SEARCH_CANCEL`
const ACTION_SUPP_AWARD_SEARCH_COMPLETE = `${featureName}/SUPP_AWARD_SEARCH_COMPLETE`
const ACTION_SUPP_AWARD_SEARCH_ERROR = `${featureName}/SUPP_AWARD_SEARCH_ERROR`

const ACTION_LIVE_AWARD_SEARCH_START = `${featureName}/LIVE_AWARD_SEARCH_START`
const ACTION_LIVE_AWARD_SEARCH_COMPLETE = `${featureName}/LIVE_AWARD_SEARCH_COMPLETE`
const ACTION_LIVE_AWARD_SEARCH_ERROR = `${featureName}/LIVE_AWARD_SEARCH_ERROR`

const ACTION_CLEAR_SEARCH_OPTIONS = `${featureName}/CLEAR_OPTIONS`

const ACTION_CLEAR_FLIGHT_SEARCH = `${featureName}/CLEAR_FLIGHT_SEARCH`
const ACTION_TOGGLE_DEBUG_MODE = `${featureName}/TOGGLE_DEBUG_MODE`

const ACTION_CALENDAR_SEARCH_START = `${featureName}/CALENDAR_SEARCH_START`
const ACTION_CALENDAR_SEARCH_CANCEL = `${featureName}/CALENDAR_SEARCH_CANCEL`
const ACTION_CALENDAR_SEARCH_COMPLETE = `${featureName}/CALENDAR_SEARCH_COMPLETE`
const ACTION_CALENDAR_SEARCH_ERROR = `${featureName}/CALENDAR_SEARCH_ERROR`

const ACTION_SET_AWARD_TICKET_AVAILABLE = `${featureName}/SET_AWARD_TICKET_AVAILABLE`

export const Actions = {
  toggleDebugMode: () => (dispatch) => {
    dispatch({ type: ACTION_TOGGLE_DEBUG_MODE })
  },

  clearFlightSearch: () => (dispatch) => {
    // Total clean-slate clear of all data.
    // Deletes all search params, itineraries, filters, and stored price data.
    dispatch({ type: ACTION_CLEAR_FLIGHT_SEARCH })
  },

  clearSearchOptions: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_SEARCH_OPTIONS })
  },

  clearItinerary: () => (dispatch) => {
    // Itinerary clear. This removes the selected itinerary and any calendar/price data
    // but leaves O&D and dates intact. A sort of partial reset.
    dispatch({ type: ACTION_CLEAR_ITINERARY })
  },

  clearItineraryOptions: () => (dispatch) => {
    dispatch({ type: ACTION_CLEAR_ITINERARY_OPTIONS })
  },

  cancelItineraries: () => (dispatch) => {
    dispatch({ type: ACTION_ITINERARY_SEARCH_CANCEL })
  },

  fetchItineraries:
    (searchParams, resetSearch = false) =>
    (dispatch) => {
      if (searchParams.ticketType.value === 'award') {
        return false
      }
      const currentItineraryRequestController = new AbortController()
      dispatch({
        type: ACTION_ITINERARY_SEARCH_START,
        controller: currentItineraryRequestController,
      })

      return API.flights
        .itinerarySearch(searchParams, resetSearch, currentItineraryRequestController)
        .then((response) => {
          dispatch({
            type: ACTION_ITINERARY_SEARCH_COMPLETE,
            payload: { ...response.data },
          })
        })
        .catch((e) => {
          if (e.message !== 'canceled') {
            dispatch({
              type: ACTION_ITINERARY_SEARCH_ERROR,
            })
          }
        })
    },

  cancelAwards: () => (dispatch) => {
    dispatch({ type: ACTION_AWARD_SEARCH_CANCEL })
    // TODO: Remove this when websockets/AF reactivated
    // dispatch({ type: ACTION_SUPP_AWARD_SEARCH_CANCEL })
  },

  fetchAwards:
    (searchParams, retryAttempt = 0) =>
    (dispatch) => {
      if (searchParams.ticketType.value === 'regular') {
        return false
      }
      if (retryAttempt > 2) {
        return dispatch({
          type: ACTION_AWARD_SEARCH_ERROR,
        })
      }
      const currentAwardRequestController = new AbortController()
      dispatch({ type: ACTION_AWARD_SEARCH_START, controller: currentAwardRequestController })

      return API.flights
        .awardSearch(searchParams, currentAwardRequestController)
        .then((response) => {
          dispatch({
            type: ACTION_AWARD_SEARCH_COMPLETE,
            payload: { ...response.data },
          })
        })
        .catch((e) => {
          if (e.response?.status === 503) {
            return dispatch(Actions.fetchAwards(searchParams, retryAttempt + 1))
          } else {
            if (e.message !== 'canceled') {
              dispatch({
                type: ACTION_AWARD_SEARCH_ERROR,
              })
            }
          }
        })
    },

  // TODO: remove when websocket award search implemented
  // fetchSupplementaryAwards:
  //   (searchParams, retryAttempt = 0) =>
  //   (dispatch) => {
  //     if (searchParams.ticketType.value == "regular"){
  //       return false
  //     }
  //     if (retryAttempt > 2) {
  //       return dispatch({
  //         type: ACTION_SUPP_AWARD_SEARCH_ERROR,
  //       })
  //     }
  //     const currentSuppAwardRequestController = new AbortController()
  //     dispatch({
  //       type: ACTION_SUPP_AWARD_SEARCH_START,
  //       controller: currentSuppAwardRequestController,
  //     })

  //     return API.flights
  //       .supplementaryAwardSearch(searchParams, currentSuppAwardRequestController)
  //       .then((response) => {
  //         dispatch({
  //           type: ACTION_SUPP_AWARD_SEARCH_COMPLETE,
  //           payload: { ...response.data },
  //         })
  //       })
  //       .catch((e) => {
  //         if (e.response?.status === 503) {
  //           return dispatch(Actions.fetchSupplementaryAwards(searchParams, retryAttempt + 1))
  //         } else {
  //           return dispatch({
  //             type: ACTION_SUPP_AWARD_SEARCH_ERROR,
  //           })
  //         }
  //       })
  //   },

  fetchLiveAwards: (searchParams) => (dispatch) => {
    dispatch({ type: ACTION_LIVE_AWARD_SEARCH_START })

    return API.flights
      .liveAwardSearch(searchParams)
      .then((response) => {
        dispatch({
          type: ACTION_LIVE_AWARD_SEARCH_COMPLETE,
          payload: { ...response.data },
        })
      })
      .catch((e) => {
        dispatch({
          type: ACTION_LIVE_AWARD_SEARCH_ERROR,
          payload: { ...e.message },
        })
      })
  },

  cancelCalendarFetches: () => (dispatch) => {
    dispatch({
      type: ACTION_CALENDAR_SEARCH_CANCEL,
    })
  },

  fetchCalendar:
    (searchParams, replace = true) =>
    (dispatch) => {
      const calendarFetchController = {
        controller: new AbortController(),
        moment: moment().unix(),
      }
      dispatch({
        replace,
        type: ACTION_CALENDAR_SEARCH_START,
        controller: calendarFetchController,
        weeks: searchParams.weeksToFetch,
      })

      const hasOutboundItinerary = !!searchParams.outboundItinerary

      if (hasOutboundItinerary) {
        return API.flights
          .calendarGridSearch(searchParams, calendarFetchController)
          .then((response) => {
            const { calendarOptions } = response.data
            const action = ACTION_CALENDAR_SEARCH_COMPLETE
            dispatch({
              type: action,
              payload: { calendarOptions },
              controller: calendarFetchController,
            })
          })
          .catch((e) => {
            // Cancellation is expected behavior and should not be treated as an error
            if (e.message !== 'canceled') {
              console.error('ACTION_CALENDAR_SEARCH_ERROR', e)
              dispatch({
                type: ACTION_CALENDAR_SEARCH_ERROR,
                payload: e,
                controller: calendarFetchController,
                weeks: searchParams.weeksToFetch,
              })
            }
          })
      } else {
        return API.flights
          .calendarSearch(searchParams, calendarFetchController)
          .then((response) => {
            const { calendarOptions } = response.data

            dispatch({
              type: ACTION_CALENDAR_SEARCH_COMPLETE,
              payload: { calendarOptions },
              controller: calendarFetchController,
            })
          })
          .catch((e) => {
            // Cancellation is expected behavior and should not be treated as an error
            if (e.message !== 'canceled') {
              console.error('ACTION_CALENDAR_SEARCH_ERROR', e)
              dispatch({
                type: ACTION_CALENDAR_SEARCH_ERROR,
                payload: e,
                controller: calendarFetchController,
                weeks: searchParams.weeksToFetch,
              })
            }
          })
      }
    },

  updateValue: (newValue) => ({
    type: ACTION_UPDATE_FLIGHT_SEARCH_VALUE,
    payload: newValue,
  }),

  updateSearchParams: (newValue) => ({
    type: ACTION_UPDATE_FLIGHT_SEARCH_PARAMS,
    payload: newValue,
  }),

  setAwardTicketAvailable: (value) => ({
    type: ACTION_SET_AWARD_TICKET_AVAILABLE,
    payload: value,
  }),
}

// REDUCER

const defaultState = {
  currentProduct: 'flights',
  stage: '1',
  searchParams: {
    currentTripType: { value: 'roundTrip', label: 'Round Trip' },
    departureDate: null,
    returnDate: null,
    airlines: [],
    maxStops: { value: '99', label: 'Unlimited Stops' },
    origin: '',
    destination: '',
    numPax: { value: 1, label: '1 Passenger' },
    travelClass: { value: 'COACH', label: 'Economy Class' },
    ticketType: null,
    connectingAirport: null,
    outboundItinerary: null,
    inboundItinerary: null,
    activeFlight: 'outbound',
    isRefundable: null,
    excludeNoCarryOn: { value: false, label: 'Include No Carry On Fares' },
    originIsCity: false,
    destinationIsCity: false,
  },
  calendarActiveDate: null,
  calendarAnchorDate: moment(), //this is stored as a momentJS object, all other dates are Date objects
  itineraryOptions: [],
  searchData: {
    airports: null,
    carriers: null,
  },
  noItineraryOptions: false,
  calendarType: 'fixedLength', // Either 'fixedLength' or 'fixedDeparture'
  calendarOptions: [],
  calendarPopulatedWeeks: [], // week numbers of prepopulated weeks
  filteredItinOptions: [],
  currentItineraryRequestController: null,
  currentAwardRequestController: null,
  calendarFetchControllers: [],
  fetchedCalendarWeeks: [],
  erroredCalendarWeeks: [],
  liveAwards: {},
  liveScrapeIsLoading: false,
  awardTicketAvailable: false,
  isItineraryError: false,
  isAwardItineraryError: false,
  error: null,
  errors: [],
  debugSwitch: false,
}

export const FlightSearchReducer = (state = defaultState, action) => {
  Object.freeze(state)

  switch (action.type) {
    case ACTION_UPDATE_FLIGHT_SEARCH_VALUE:
      return {
        ...state,
        ...action.payload,
      }

    case ACTION_UPDATE_FLIGHT_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload,
        },
      }

    case ACTION_ITINERARY_SEARCH_START:
      if (state.currentItineraryRequestController) {
        state.currentItineraryRequestController.abort()
      }
      return {
        ...state,
        isItineraryError: false,
        currentItineraryRequestController: action.controller,
      }

    case ACTION_ITINERARY_SEARCH_CANCEL:
      if (state.currentItineraryRequestController) {
        state.currentItineraryRequestController.abort()
      }
      return {
        ...state,
        currentItineraryRequestController: null,
      }

    case ACTION_ITINERARY_SEARCH_COMPLETE:
      return {
        ...state,
        isItineraryError: false,
        itineraryOptions: state.itineraryOptions
          .concat(action.payload.itineraryOptions)
          .sort((a, b) => (a.priceTotal < b.priceTotal ? -1 : 1)),
        currentItineraryRequestController: null,
        searchData: {
          ...state.searchData,
          ...action.payload.searchData,
        },
      }

    case ACTION_ITINERARY_SEARCH_ERROR:
      return {
        ...state,
        ...action.payload,
        isItineraryError: true,
      }

    case ACTION_AWARD_SEARCH_START:
      if (state.currentAwardRequestController) {
        state.currentAwardRequestController.abort()
      }
      return {
        ...state,
        isAwardItineraryError: false,
        currentAwardRequestController: action.controller,
      }

    case ACTION_AWARD_SEARCH_CANCEL:
      if (state.currentAwardRequestController) {
        state.currentAwardRequestController.abort()
      }
      return {
        ...state,
        currentAwardRequestController: null,
      }

    case ACTION_AWARD_SEARCH_COMPLETE:
      return {
        ...state,
        isAwardItineraryError: false,
        itineraryOptions: state.itineraryOptions
          .concat(action.payload.itineraryOptions)
          .sort((a, b) => (a.priceTotal < b.priceTotal ? -1 : 1)),
        currentAwardRequestController: null,
        searchData: {
          ...state.searchData,
          ...action.payload.searchData,
        },
      }

    case ACTION_AWARD_SEARCH_ERROR:
      return {
        ...state,
        isAwardItineraryError: true,
      }

    case ACTION_SUPP_AWARD_SEARCH_START:
      if (state.currentSuppAwardRequestController) {
        state.currentSuppAwardRequestController.abort()
      }
      return {
        ...state,
        currentSuppAwardRequestController: action.controller,
      }

    case ACTION_SUPP_AWARD_SEARCH_CANCEL:
      if (state.currentSuppAwardRequestController) {
        state.currentSuppAwardRequestController.abort()
      }
      return {
        ...state,
        currentSuppAwardRequestController: null,
      }

    case ACTION_SUPP_AWARD_SEARCH_COMPLETE:
      return {
        ...state,
        itineraryOptions: state.itineraryOptions
          .concat(action.payload.itineraryOptions)
          .sort((a, b) => (a.priceTotal < b.priceTotal ? -1 : 1)),
        currentSuppAwardRequestController: null,
        searchData: {
          ...state.searchData,
          ...action.payload.searchData,
        },
      }

    case ACTION_SUPP_AWARD_SEARCH_ERROR:
      return {
        ...state,
        currentSuppAwardRequestController: null,
      }

    case ACTION_CALENDAR_SEARCH_START:
      // If we're starting a fresh search, cancel all active fetches
      if (action.replace) {
        state.calendarFetchControllers.forEach((controller) => {
          controller.controller.abort()
        })
      }

      // If we're starting a fresh search, reset fetched weeks, otherwise merge them in
      const fetchedCalendarWeeks = new Set(
        action.replace
          ? action.weeks
          : [...Array.from(state.fetchedCalendarWeeks), ...action.weeks],
      )

      // If we're starting a fresh search, cancel all active fetches
      const erroredCalendarWeeks = state.erroredCalendarWeeks.filter(
        (errorWeek) => !action.weeks.includes(errorWeek),
      )

      return {
        ...state,
        fetchedCalendarWeeks: Array.from(fetchedCalendarWeeks),
        calendarFetchControllers: action.replace
          ? [action.controller]
          : [...state.calendarFetchControllers, action.controller],
        calendarOptions: action.replace ? [] : state.calendarOptions,
        erroredCalendarWeeks,
      }

    case ACTION_CALENDAR_SEARCH_CANCEL:
      state.calendarFetchControllers.forEach((controller) => {
        controller.controller.abort()
      })

      return {
        ...state,
        calendarFetchControllers: [],
      }

    case ACTION_CALENDAR_SEARCH_COMPLETE:
      let mergedDates = state.calendarOptions.concat(action.payload.calendarOptions)

      return {
        ...state,
        isCalendarError: false,
        calendarFetchControllers: state.calendarFetchControllers.filter(
          (controller) => controller.moment !== action.controller.moment,
        ),
        calendarOptions: mergedDates,
      }

    case ACTION_CALENDAR_SEARCH_ERROR:
      return {
        ...state,
        error: action.payload,
        calendarFetchControllers: state.calendarFetchControllers.filter(
          (controller) => controller.moment !== action.controller.moment,
        ),
        isCalendarError: true,
        erroredCalendarWeeks: Array.from(new Set([...state.erroredCalendarWeeks, ...action.weeks])),
      }

    case ACTION_CLEAR_ITINERARY:
      return {
        ...defaultState,
        searchParams: {
          ...state.searchParams,
          outboundItinerary: null,
          inboundItinerary: null,
          activeFlight: 'outbound',
        },
        calendarActiveDate: state.searchParams.departureDate,
        calendarAnchorDate: moment(state.searchParams.departureDate),
      }

    case ACTION_CLEAR_SEARCH_OPTIONS:
      return {
        ...state,
        itineraryOptions: [],
        calendarOptions: [],
        filteredItinOptions: [],
        calendarPopulatedWeeks: [],
      }

    case ACTION_CLEAR_ITINERARY_OPTIONS:
      return {
        ...state,
        itineraryOptions: [],
      }

    case ACTION_CLEAR_FLIGHT_SEARCH:
      return {
        ...defaultState,
      }

    case ACTION_TOGGLE_DEBUG_MODE:
      return {
        ...state,
        debugSwitch: !state.debugSwitch,
      }

    case ACTION_LIVE_AWARD_SEARCH_START:
      return {
        ...state,
        liveScrapeIsLoading: true,
        awardTicketAvailable: false,
      }

    case ACTION_LIVE_AWARD_SEARCH_COMPLETE:
      return {
        ...state,
        liveAwards: action.payload,
        liveScrapeIsLoading: false,
      }

    case ACTION_LIVE_AWARD_SEARCH_ERROR:
      return {
        ...state,
        liveAwards: defaultState.liveAwards,
        liveScrapeIsLoading: defaultState.liveScrapeIsLoading,
        awardTicketAvailable: defaultState.awardTicketAvailable,
      }

    case ACTION_SET_AWARD_TICKET_AVAILABLE:
      return {
        ...state,
        awardTicketAvailable: action.payload,
      }

    default:
      return state
  }
}
