import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment'

import { Img } from 'react-image'
import { FormattedMessage } from 'react-intl';

import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components';

import { USDCurrencyOptions, IETCodes, Locale } from 'utils/constants';
import {ToggleFeature, isFeatureEnabled, ToggleStatus} from 'utils/toggles';
import { getDurationAsString } from 'utils/utils';

import Button, { ButtonType } from 'components/common/Button';

import {TravelClass, messages, SearchSourceToDisplayText} from "components/pages/Rewards/constants"
import { fetchSeatMap, toggleSeatMapIsOpen } from 'components/pages/Rewards/Flights/flightsSlice';
import { isAmenityPopulated } from 'components/pages/Rewards/Flights/utils';

import { ReactComponent as CaretDown } from "assets/svg/CaretDown.svg";
import { ReactComponent as CaretUp } from "assets/svg/CaretUp.svg";

import styles from 'styles/styles';

const expandedVariants = {
    open: { height: 'auto', opacity: 1 },
    closed: { height: 0, opacity: 0 },
}

type ItineraryOptionProps = {
    // replace any typing with ItineraryOption
    options: any[],
    onItinerarySelect: (itinerary: any) => void,
    searchOptions: any,
    isPickingReturnDate: boolean
}

const ItineraryOption = (props: ItineraryOptionProps) => {
    const dispatch = useDispatch()

    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const currentUser = useSelector((state: any) => state.currentUser.currentUser)
    const debugSwitch = useSelector((state: any) => state.flightSearch.debugSwitch)

    // Component state
    const [isOpen, setIsOpen] = useState(false)

    // Feature Toggles
    const isSeatmapsEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.Seatmaps)
    const isCarryonIconEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.CarryOn)
    const isDebugModeEnabled = isFeatureEnabled(currentUser.toggleStatus, ToggleFeature.DebugMode)
    const showDebug = debugSwitch && isDebugModeEnabled

    const toggleIsOpen = () => { 
        setIsOpen(!isOpen)
    }

    const reduceTotalLayoverTime = (a: any, b: any) => a + (b.connection || 0);

    const selectItinerary = () => {
        const { options, onItinerarySelect } = props;

        onItinerarySelect(options[0])
    }

    const { options, searchOptions } = props;
    const option = options[0] // todo select first option for now. we will later display all options

    const carrierCode = IETCodes.includes(option?.carrierCode) ? option?.slices?.[0]?.segments?.[0]?.operatingCarrierCode?.toUpperCase() : option?.carrierCode?.toUpperCase();
    const carryOnIcon = () => {
        const carryOnCount: number = option?.carryonBaggageAllowance
        const carryOnKilos: number = option?.carryonBaggageAllowanceKilos

        if (carryOnCount === undefined && carryOnKilos === undefined) {
            return <InlineIcon mobile={isMobile} src={require(`assets/old/img/amenities/${"carryon-unknown".toLowerCase()}.svg`)} alt=""/>
        } else if (carryOnCount > 0 || carryOnKilos > 0) {
            return <></>
        } else {
            return <InlineIcon mobile={isMobile} src={require(`assets/old/img/amenities/${"no-carryon".toLowerCase()}.svg`)} alt=""/>
        }
    }

    const isDeparturePointMismatched = (searchOptions.departureAirport?.isCity && searchOptions.departureAirport?.isCity === true) ? false : (props.isPickingReturnDate ? option?.slices?.[0]?.segments?.[0]?.departurePoint !== searchOptions.arrivalAirport?.value : option?.slices?.[0]?.segments?.[0]?.departurePoint !== searchOptions.departureAirport?.value);
    
    const isArrivalPointMismatched = (searchOptions.arrivalAirport?.isCity && searchOptions.arrivalAirport?.isCity === true) ? false : (props.isPickingReturnDate ? option?.slices?.[0]?.segments?.at(-1)?.arrivalPoint !== searchOptions.departureAirport?.value : option?.slices?.[0]?.segments?.at(-1)?.arrivalPoint !== searchOptions.arrivalAirport?.value);
    
    const arrivalDateDiffInDays = Number(moment(option?.slices?.at(-1)?.segments?.at(-1)?.localArrivalTimeDate).format('DD')) - Number(moment(option?.slices?.[0]?.segments?.[0]?.localDepartureTimeDate).format('DD'));

    const marketingVsOperatingCarrierMismatch = (option: any, segment: any): boolean => {
        const carrierCodeMismatch = (segment.carrierCode !== segment.operatingCarrierCode || segment.operatingCarrierCode !== option.validatingCarrier)
        const carrierNameMismatch = option.airlineName !== segment.operatingCarrierName
        return carrierCodeMismatch && carrierNameMismatch
    }

    const buildSeatmapTitle = (segment: any) => {
        return `${segment.originCityName} to ${segment.destinationCityName} (${segment.carrierCode} ${segment.fltNumber}) on ${moment(segment.localDepartureTimeDate).format('MMM D, Y')}`
    }

    const openSeatMapModal = (segment: any) => {
        dispatch(toggleSeatMapIsOpen(buildSeatmapTitle(segment)))
        dispatch(fetchSeatMap({
            localDepartureTimeDate: segment.departureTimeDate,
            origin: segment.origin,
            destination: segment.arrivalPoint,
            carrierCode: segment.carrierCode,
            fltNumber: segment.fltNumber,
            bookingCode: segment.bookingCode,
        }))
    }

    return <Container>
        <Header onClick={toggleIsOpen} isOpen={isOpen}>
            {option?.isAwardTicket && <AwardCalendarIndicator/>}
            {option?.isNdcTicket && <NdcCalendarIndicator/>}
            <AirlineLogoContainer>
                <HeaderLogo src={[`/static/img/airlineLogos/mini/${carrierCode}.png`,`/static/img/airlineLogos/mini/default.png`]} />
            </AirlineLogoContainer>
            <HeaderDetails>
                {isMobile ? <>
                    <div className='d-flex flex-column justify-content-center'>
                        <strong>{`${moment(option.slices[0].segments[0].localDepartureTimeDate).format('h:mm A')} - ${moment(option.slices.at(-1).segments.at(-1).localArrivalTimeDate).format('h:mm A')}`}{arrivalDateDiffInDays > 0 && <Superscript>+{arrivalDateDiffInDays}</Superscript>}</strong>
                        <div>{option.airlineName || "Unknown Airline"} {isCarryonIconEnabled && carryOnIcon()}</div>
                        <div>{getDurationAsString(option.slices[0].duration)}</div>
                    </div>
                    <div className='d-flex flex-column align-items-end justify-content-center'>
                        <div><MismatchedValue isMismatched={isDeparturePointMismatched}>{option.slices[0]?.segments[0]?.departurePoint}</MismatchedValue> - <MismatchedValue isMismatched={isArrivalPointMismatched}>{option.slices.at(-1).segments.at(-1).arrivalPoint}</MismatchedValue></div>
                        <div>{parseInt(option.slices[0].numStops) !== 0 ? `${option.slices[0].numStops} stop${option.slices[0].numStops > 1 ? 's' : ''}` : 'Nonstop'} {parseInt(option.slices[0].numStops) !== 0 && <span style={{opacity: 0.5}}>({getDurationAsString(option.slices[0].segments.reduce(reduceTotalLayoverTime, 0))})</span>}</div>
                        <strong>
                            {option.isAwardTicket ? <div>
                                {(option.pricePoints).toLocaleString()} PTS + {(option?.priceTaxes || 0).toLocaleString(Locale.English, USDCurrencyOptions)}
                            </div> : <div>
                                {(option.priceTotal * 100).toLocaleString()} PTS
                            </div>}
                        </strong>
                    </div>
                </> : <>
                    <HeaderDetailRow>
                    <HeaderDetail>
                        <div>
                            <Bold>
                                {`${moment(option?.slices?.[0]?.segments?.[0]?.localDepartureTimeDate).format('h:mm A')} - ${moment(option?.slices?.at(-1)?.segments?.at(-1)?.localArrivalTimeDate).format('h:mm A')}`}
                                {arrivalDateDiffInDays > 0 && <Superscript>+{arrivalDateDiffInDays}</Superscript>}
                            </Bold>
                            <div>{option?.airlineName || "Unknown Airline"}</div>
                            {showDebug &&
                                <div>
                                    <Superscript>{SearchSourceToDisplayText[option?.searchSource] || "?"}</Superscript>
                                </div>
                            }
                        </div>
                    </HeaderDetail>
                    <HeaderDetail className='pl-4'>
                        <div>
                            <Bold>{getDurationAsString(option?.slices?.[0]?.duration)}</Bold>
                            <div>
                                <MismatchedValue isMismatched={isDeparturePointMismatched}>{option?.slices?.[0]?.segments?.[0]?.departurePoint}</MismatchedValue> - <MismatchedValue isMismatched={isArrivalPointMismatched}>{option?.slices?.at(-1)?.segments?.at(-1)?.arrivalPoint}</MismatchedValue>
                            </div>
                            </div>
                    </HeaderDetail>
                    </HeaderDetailRow>
                    <HeaderDetailRow>
                        <HeaderDetail className='pl-4'>
                            <div>
                                <Bold>{parseInt(option?.slices?.[0]?.numStops) !== 0 ? `${option?.slices?.[0]?.numStops} stop${parseInt(option?.slices?.[0]?.numStops) > 1 ? 's' : ''}` : 'Nonstop'}</Bold>
                                {option?.slices?.[0].numStops !== "0" && <div>{getDurationAsString(option?.slices?.[0].segments.reduce(reduceTotalLayoverTime, 0))}</div>}
                            </div>
                        </HeaderDetail>
                        {isCarryonIconEnabled && <HeaderDetail className='pl-2'>
                            <div className='d-flex align-items-center'>
                                {carryOnIcon()}
                            </div>
                        </HeaderDetail>
                        }
                        {showDebug && <HeaderDetail className='d-flex flex-column'>
                            <Superscript>count: {option?.carryonBaggageAllowance}</Superscript>
                            <Superscript>weight: {option?.carryonBaggageAllowanceKilos}</Superscript>
                        </HeaderDetail>
                        }
                        <HeaderDetail className='pl-2'>
                            {option?.isAwardTicket ? <div>
                                <Bold>{(option?.pricePoints).toLocaleString()} PTS</Bold>
                                <div>+ {(option?.priceTaxes || 0).toLocaleString(Locale.English, USDCurrencyOptions)}</div>
                            </div> : <div>
                                <Bold>{(option?.priceTotal * 100).toLocaleString()} PTS</Bold>
                            </div>}
                        </HeaderDetail>
                    </HeaderDetailRow>
                </>}
            </HeaderDetails>
            <CaretIconContainer>
                {isOpen ? <CaretUp/> : <StyledCaretDown/>}
            </CaretIconContainer>
        </Header>
        <AnimatePresence>
            {isOpen && <ExpandedContent initial='closed' animate='open' exit='closed' variants={expandedVariants} transition={{when: "beforeChildren", staggerChildren: 3.0}}>
                {option.slices[0].segments.map((segment: any, segmentIndex: number) => segment.legs.map((leg: any, legIndex: number) => {
                    const departureDayDiff =  Number(moment(leg.localDepartureTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))
                    const arrivalDayDiff =  Number(moment(leg.localArrivalTimeDate).format('DD')) - Number(moment(option.slices[0].segments[0].localDepartureTimeDate).format('DD'))
                    return <div key={`${segmentIndex}-${legIndex}`}>
                        <FlightDetails>
                            <LegContainer>
                                <VerticalLegBar>
                                    <LargeDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <SmallDot/>
                                    <LargeDot/>
                                </VerticalLegBar>
                                <LegDetailsContainer>
                                    <LegAirportLabel>{moment(leg.localDepartureTimeDate).format('h:mm a')}{departureDayDiff > 0 && <Superscript>+{departureDayDiff}</Superscript>} &bull; <MismatchedValue isMismatched={segmentIndex === 0 && legIndex === 0 && isDeparturePointMismatched}>{`${leg.departurePointName || leg.originCityName} (${leg.origin})`}</MismatchedValue></LegAirportLabel>
                                    <LegTravelTime>Travel Time: {getDurationAsString(Number(leg.duration))}</LegTravelTime>
                                    <LegAirportLabel>{moment(leg.localArrivalTimeDate).format('h:mm a')}{arrivalDayDiff > 0 && <Superscript>+{arrivalDayDiff}</Superscript>} &bull; <MismatchedValue isMismatched={segmentIndex === option.slices[0].segments.length - 1 && legIndex === segment.legs.length - 1 && isArrivalPointMismatched}>{`${leg.arrivalPointName || leg.destinationCityName} (${leg.destination})`}</MismatchedValue></LegAirportLabel>
                                </LegDetailsContainer>
                            </LegContainer>
                            {!isMobile && <Amenities>
                                {isAmenityPopulated(leg.amenities.seatType) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/${leg.amenities.seatType?.split('_').at(0)}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.${leg.amenities.seatType.split('_')[0]}`} values={{ legroom: leg.amenities.seatType.split('_')[1] || null }} />
                                </div>}
                                {isAmenityPopulated(leg.amenities.wifi) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/wifi-${leg.amenities.wifi?.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.wifi-${leg.amenities.wifi.toLowerCase()}`} />
                                </div>}
                                {isAmenityPopulated(leg.amenities.power) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/power-${leg.amenities.power?.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.power-${leg.amenities.power}`} />
                                </div>}
                                {isAmenityPopulated(leg.amenities.entertainment) && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/entertainment-${leg.amenities.entertainment?.toLowerCase()}.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.entertainment-${leg.amenities.entertainment}`} />
                                </div>}
                                {leg.amenities.skypub && <div className='d-flex align-items-center'>
                                    <AmenityIcon src={require(`assets/old/img/amenities/skypub.svg`)} alt="" />
                                    <FormattedMessage id={`booking.amenities.skypub`} />
                                </div>}
                            </Amenities>}
                        </FlightDetails>
                        <AirplaneDetails>
                            {isMobile ? <div className='w-100 d-flex flex-column' >
                                <div style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                                    <Logo src={[
                                        `/static/img/airlineLogos/mini/${(segment.operatingCarrierCode || carrierCode).toUpperCase()}.png`,
                                        '/static/img/airlineLogos/mini/default.png'
                                    ]} />
                                    <div>
                                        {segment.operatingCarrierName} &bull; {messages.SearchOptions.TravelClass[segment.cabin as TravelClass]} &bull; {segment.aircraft || <span style={{ opacity: 0.5 }}>Aircraft model not available</span>} &bull; {segment.carrierCode} {segment.fltNumber}
                                        {marketingVsOperatingCarrierMismatch(option, segment) && <OperatedBy>
                                            <CarrierDetails>
                                                Ticket sold by {option.airlineName} &bull; Flight operated by {segment.operatingCarrierName}
                                            </CarrierDetails>
                                        </OperatedBy>}
                                    </div>
                                </div>
                                <Amenities>
                                    {isAmenityPopulated(leg.amenities.seatType) &&
                                        <div className='d-flex align-items-center'>
                                            <AmenityIcon
                                                src={require(`assets/old/img/amenities/${leg.amenities.seatType.split('_')[0]}.svg`)}
                                                alt=""/>
                                            <FormattedMessage
                                                id={`booking.amenities.${leg.amenities.seatType.split('_')[0]}`}
                                                values={{legroom: leg.amenities.seatType.split('_')[1] || null}}/>
                                        </div>}
                                    {isAmenityPopulated(leg.amenities.wifi) &&
                                        <div className='d-flex align-items-center'>
                                            <AmenityIcon
                                                src={require(`assets/old/img/amenities/wifi-${leg.amenities.wifi.toLowerCase()}.svg`)}
                                                alt=""/>
                                            <FormattedMessage
                                                id={`booking.amenities.wifi-${leg.amenities.wifi.toLowerCase()}`}/>
                                        </div>}
                                    {isAmenityPopulated(leg.amenities.power) &&
                                        <div className='d-flex align-items-center'>
                                            <AmenityIcon
                                                src={require(`assets/old/img/amenities/power-${leg.amenities.power.toLowerCase()}.svg`)}
                                                alt=""/>
                                            <FormattedMessage id={`booking.amenities.power-${leg.amenities.power}`}/>
                                        </div>}
                                    {isAmenityPopulated(leg.amenities.entertainment) &&
                                        <div className='d-flex align-items-center'>
                                            <AmenityIcon
                                                src={require(`assets/old/img/amenities/entertainment-${leg.amenities.entertainment.toLowerCase()}.svg`)}
                                                alt=""/>
                                            <FormattedMessage
                                                id={`booking.amenities.entertainment-${leg.amenities.entertainment}`}/>
                                        </div>}
                                    {leg.amenities.skypub && <div className='d-flex align-items-center'>
                                        <AmenityIcon src={require(`assets/old/img/amenities/skypub.svg`)} alt=""/>
                                        <FormattedMessage id={`booking.amenities.skypub`}/>
                                    </div>}
                                </Amenities>
                            </div> : <div className='d-flex align-items-center' style={{whiteSpace: 'pre'}}>
                                <Logo src={[
                                    `/static/img/airlineLogos/mini/${(segment.operatingCarrierCode || carrierCode).toUpperCase()}.png`,
                                    '/static/img/airlineLogos/mini/default.png'
                                ]} />
                                <div>
                                    {segment.operatingCarrierName} &bull; <ClickableDetail onClick={isSeatmapsEnabled ? (() => openSeatMapModal(segment)) : undefined}>{`${messages.SearchOptions.TravelClass[segment.cabin as TravelClass]}`}</ClickableDetail> &bull; {segment.aircraft || <span style={{ opacity: 0.5 }}>Aircraft type unknown</span>} &bull; {segment.carrierCode} {segment.fltNumber}
                                    {marketingVsOperatingCarrierMismatch(option, segment) && <OperatedBy>
                                        <CarrierDetails>
                                            Ticket sold by {option.airlineName} &bull; Flight operated by {segment.operatingCarrierName}
                                        </CarrierDetails>
                                    </OperatedBy>}
                                </div>
                            </div>}
                        </AirplaneDetails>
                        {!!segment.connection && <ConnectionDetails>
                            {getDurationAsString(segment.connection)} &bull; {segment.arrivalPoint === option.slices[0].segments[segmentIndex + 1].departurePoint ? `${segment.arrivalPointName} (${segment.arrivalPoint})` : <MismatchedValue isMismatched>Change of airport ({`${segment.arrivalPoint} to ${option.slices[0].segments[segmentIndex + 1].departurePoint}`})</MismatchedValue>}
                        </ConnectionDetails>}
                    </div>}
                ))}
                <LineBreak/>
                <Footer>
                    <FooterDetailsContainer>
                        <div>{`Total Travel Time: ${getDurationAsString(option.slices[0].duration)}`}</div>
                        <TotalCostLabel>
                            {`Total cost: ${
                                option.isAwardTicket 
                                ? `${(option.pricePoints).toLocaleString()} pts. + ${(option.priceTaxes || 0).toLocaleString(Locale.English, USDCurrencyOptions)}`
                                : `${(option.priceTotal * 100).toLocaleString()} pts.`
                            }`}
                        </TotalCostLabel>
                    </FooterDetailsContainer>
                    <ConfirmButtonContainer onClick={selectItinerary} >
                        <Button label='Select Flight' buttonType={ButtonType.Purple} />
                    </ConfirmButtonContainer>
                </Footer>
            </ExpandedContent>}
        </AnimatePresence>
    </Container>
}

type ClickableDetailProps = {
    onClick?: any
}

const ClickableDetail = styled.span<ClickableDetailProps>`
    white-space:pre;
    ${props => props.onClick && `
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    `}
`

const Superscript = styled.span`
    vertical-align: super;
    font-size: 10px;
`

const OperatedBy = styled.div`
    display: flex;
    align-items: center;
`

const CarrierDetails = styled.div`
    font-size: 10px;
`

const FlightDetails = styled.div`
    display: flex;
    ${styles.MediaQueries.Desktop} {
        justify-content: space-between;
        margin-left: 20px;
    }
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        margin-left: 10px;
    }
`

type AwardCalendarIndicatorProps = {
    isAwardTicket?: boolean
}

const AwardCalendarIndicator = styled.div<AwardCalendarIndicatorProps>`
    border-left: 10px solid ${props => props.isAwardTicket ? styles.Color.TaekusPurple : '#FDFBF6'};
    border-right: 10px solid ${props => props.isAwardTicket ? styles.Color.TaekusPurple : '#FDFBF6'};
    border-bottom: 10px solid ${props => props.isAwardTicket ? '#FDFBF6' : styles.Color.TaekusPurple};
    rotate: 225deg;
    position: absolute;
    bottom: -2px;
    left: -9px;
    ${styles.Animation.transitionStyles}
`

type NdcCalendarIndicatorProps = {
    isNdcTicket?: boolean;
};

const NdcCalendarIndicator = styled.div<NdcCalendarIndicatorProps>`
    width: 1px;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const StyledCaretDown = styled(CaretDown)`
    width: 10px;
    height: 5px;
`

const LegContainer = styled.div`
    display: flex;
    flex: 1;
    height: fit-content;
`

const LineBreak = styled.div`
    border-top: 1px solid ${styles.Color.Grey};
    ${styles.MediaQueries.Desktop} {
        margin: 0 46px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 20px;
    }
`

const ExpandedContent = styled(motion.div)`
    padding-top: ${styles.Spacing.XS};
    border-left: 2px solid ${styles.Color.TaekusPurple};
    font-family: ${styles.Font.Family.MonumentGrotesk};
`

const AirlineLogoContainer = styled.div`
    width: 45px;
    display: flex;
    justify-content: center;
`

type MismatchedValueProps = {
    isMismatched: boolean
}

const MismatchedValue = styled.span<MismatchedValueProps>`
    ${props => props.isMismatched && `
        ${styles.Animation.transitionStyles}
        color: #fd2d2d;
        font-weight: bold;
    `}
`

const HeaderDetailRow = styled.div`
    display: flex;
    align-items: center;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        height: 50%;
        width: 100%;
        padding: 0 ${styles.Spacing.XS};
        justify-content: space-between;
        flex: 1;
    }
    ${styles.MediaQueries.Desktop} {
        height: 100%;
        width: 50%;
        justify-content: space-between;
        &:last-child {
            justify-content: space-around;
        }
    }
`

const HeaderDetails = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    ${styles.MediaQueries.Mobile} {
        justify-content: space-between;
    }
`

const Amenities = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    font-size: ${styles.Font.Size.Small};
    margin-right: 46px;
    font-size: 12px;
    opacity: 0.5;
`

const AmenityIcon = styled.img`
    margin: 4px 8px 4px 0;
    width: 14px;
    height: auto;
`

type InlineIconProps = {
    mobile: boolean
}

const InlineIcon = styled.img<InlineIconProps>`
    margin: ${props => props.mobile ? '4px 8px 4px 0' : null};
    width: 20px;
    height: auto;
`
const HeaderDetail = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
`

const VerticalLegBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${styles.MediaQueries.Desktop} {
        margin-right: 8px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-right: 8px;
    }
`

const AirplaneDetails = styled.div`
    display: flex;
    align-items: center;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.MediaQueries.Desktop} {
        margin-left: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: ${styles.Font.Size.Small};
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
        padding: 20px 10px;
    }
`

const ConnectionDetails = styled.div`
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: ${styles.Spacing.XS} 0;
    font-size: ${styles.Font.Size.Small};
    ${styles.MediaQueries.Desktop} {
        margin: 0 46px 30px 46px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 20px 10px 20px;
    }
`

const LegAirportLabel = styled.div`
    font-size: ${styles.Font.Size.Small};
    font-weight: bold;
`

const LegTravelTime = styled.div`
    font-size: ${styles.Font.Size.Small};
    flex: 1;
    display: flex;
    align-items: center;
    opacity: 0.5;
`

const LegDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 120px;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        justify-content: space-around;
    }
    ${styles.MediaQueries.Desktop} {
        align-items: center;
    }
`

const FooterDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    ${styles.MediaQueries.Desktop} {
        margin-left: 30px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-left: 16px;
        height: 50%;
    }
`

const TotalCostLabel = styled.div`
    font-size: ${styles.Font.Size.Small};
    font-weight: bold;
`

const Bold = styled.div`
    font-weight: bold;
`

const HeaderLogo = styled(Img)`
    width: auto;
    height: 25px;
`

const Logo = styled(Img)`
    ${styles.MediaQueries.Desktop} {
        width: 25px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 14px;
    }
    height: auto;
    margin: 4px 8px 4px 0;
`

const CaretIconContainer = styled.div`
    display: flex;
    align-items: center;
    width: ${styles.Spacing.M};
    ${styles.MediaQueries.Mobile} {
        justify-content: center;
    }
`

type HeaderProps = {
    isOpen: boolean;
}

const Header = styled.div<HeaderProps>`
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    font-size: ${styles.Font.Size.Small};
    border-left: 2px solid ${props => props.isOpen ? styles.Color.TaekusPurple : 'transparent'};
    align-items: center;
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Mobile} {
        height: 80px;
    }
    ${styles.MediaQueries.Desktop} {
        height: 80px;
        &:hover {
            background-color: rgba(124, 61, 118, 0.15);
        }
    }
`

const LargeDot = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #D9D9D9;
    margin: 3.5px 0;
`

const SmallDot = styled.div`
    background-color: #D9D9D9;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 3px 0;
`

const ConfirmButtonContainer = styled.div`
    ${styles.MediaQueries.Mobile} {
        flex: 1;
        margin: 0 10px;
        height: 50%;
        display: flex;
        align-items: center;
    }
    ${styles.MediaQueries.Desktop} {
        height: ${styles.Spacing.L};
        margin-right: 36px;
        width: 160px;
    }
`

const Container = styled.div`
    border-bottom: 1px solid ${styles.Color.Grey};
    height: min-content;
    overflow: hidden;
    ${styles.Animation.transitionStyles}
`

export default ItineraryOption