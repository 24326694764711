import React from "react"
import { useSelector } from "react-redux"

import { noop } from "lodash"

import styled from "styled-components"

import Checkbox from "components/common/Checkbox"

import { TravelerDetails } from "components/pages/Rewards/Flights/types"
import { PassengerFieldToggles } from "components/pages/Rewards/Flights/toggles"
import { isPassengerDetailsValid } from "components/pages/Rewards/Flights/FlightBooking/util"

import styles from "styles/styles"

import { ReactComponent as ArrowRight } from "assets/svg/ArrowRight.svg";
import { ReactComponent as TravelerIcon } from "assets/svg/Traveler.svg";


type TravelerProps = {
    editTraveler: (traveler: TravelerDetails) => void,
    traveler: TravelerDetails,
    selected: boolean,
    selectTraveler: (passengerUuid: string) => void,
    uuid: string,
}

const Traveler = (props: TravelerProps) => {
    // Redux state
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const priceDetail = useSelector((state: any) => state.flightBook.priceDetail)
    const isPassportImageRequired = priceDetail?.reservations?.some((reservation: any) => reservation?.transferPartnerData?.partnerCode === 'VN')
    // Field level toggles
    const requiredFields = priceDetail?.requiredFields
    const citizenshipCountryEnabled = requiredFields?.includes(PassengerFieldToggles.CitizenshipCountryCode)
    const residenceCountryEnabled = requiredFields?.includes(PassengerFieldToggles.ResidenceCountryCode)

    const handlePassengerSelect = () => {
        const { selectTraveler, uuid } = props;
        selectTraveler(uuid)
    }

    const handlePassengerInspect = () => {
        const { editTraveler, traveler } = props;

        editTraveler(traveler)
    }

    const { traveler, selected } = props;
    const isValid = isPassengerDetailsValid(props.traveler, priceDetail.isDomesticItinerary, isPassportImageRequired, citizenshipCountryEnabled, residenceCountryEnabled)

    return <Container
        selected={selected}
        onClick={handlePassengerSelect}
        onDoubleClick={handlePassengerInspect}
    >
        <DetailsContainer>
            <Checkbox checked={selected} onChange={noop} />
            <StyledTravelerIcon selected={selected}/>
            <div>
                <div>{traveler.firstName} {traveler.lastName}</div>
                <div>Adult</div>
                {isMobile && <div className="d-flex align-items-center">
                    <CompletionIndicator isValid={isValid} />
                    <CompletionLabel>{isValid ? 'Complete' : 'Missing details'}</CompletionLabel>
                </div>}
            </div>
        </DetailsContainer>
        <ArrowContainer>
            {!isMobile && <div className="d-flex align-items-center">
                <CompletionIndicator isValid={isValid} />
                <CompletionLabel>{isValid ? 'Complete' : 'Missing details'}</CompletionLabel>
            </div>}
            <StyledArrowRight onClick={handlePassengerInspect}/>
        </ArrowContainer>
    </Container>
}

type SelectedProps = {
    selected: boolean
}

const DetailsContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

type CompletionIndicatorProps = {
    isValid: boolean,
}

const CompletionIndicator = styled.div<CompletionIndicatorProps>`
    width: 11px;
    height: 11px;
    background-color: ${props => props.isValid ? '#56BC39' : '#F60000'};
    border-radius: 50%;
    ${styles.MediaQueries.Desktop} {
        margin-right: 10px;
    }
    ${styles.MediaQueries.Mobile} {
        margin-right: 6px;
    }   
`

const CompletionLabel = styled.div`
    opacity: 0.5;
`

const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${styles.MediaQueries.Desktop} {
        flex: 1;
    }
`

const StyledArrowRight = styled(ArrowRight)`
    fill: ${styles.Color.TaekusPurple};
    height: 12px;
    width: auto;
`

const StyledTravelerIcon = styled(TravelerIcon)<SelectedProps>`
    width: 42px;
    height: auto;
    fill: ${props => props.selected ? styles.Color.TaekusPurple : '#80818A'};
    ${styles.Animation.transitionStyles}
    ${styles.MediaQueries.Desktop} {
        margin: 0 20px;
    }
    ${styles.MediaQueries.Mobile} {
        margin: 0 10px;
    }
`



const Container = styled.div<SelectedProps>`
    height: 90px;
    display: flex;
    align-items: center;
    border: ${props => props.selected ? `2px solid ${styles.Color.TaekusPurple} !important` : `1px solid #7D7D7D`};
    padding: ${props => props.selected ? `0 20px` : `1px 21px`};
    margin-bottom: 10px;
    user-select: none;
    font-family: ${styles.Font.Family.MonumentGrotesk};
    ${styles.Animation.transitionStyles}
    cursor: pointer;
    ${styles.MediaQueries.Desktop} {
        &:hover {
            border: 1px solid ${styles.Color.TaekusPurple};
        }
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 14px;
    }
`

export default Traveler